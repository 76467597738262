<template>
  <div class="d-flex flex-column fill-height">
    <div>
      <v-simple-table height="300" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Fecha y Hora</span>
                  <v-btn
                    v-if="itemsTable && itemsTable.length"
                    color="success"
                    @click="toExcel"
                    icon
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </th>
              <th class="text-left">Nombre</th>
              <th class="text-left">Usuario</th>
              <th class="text-left">IP</th>
              <th class="text-left">Origen</th>
              <th class="text-left">Acción</th>
              <th class="text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in itemsTable" :key="item.id">
              <td>{{ item.serverTime | datetime }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.ipAddress }}</td>
              <td>{{ item.origin }}</td>
              <td>{{ item.action }}</td>
              <td>{{ item.status }}</td>
              <!-- <td></td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'underscore';
import Dialog from '@/components/dialog';
import Form from '@/components/form';
import ApiService from '@/util/api.service';
import { periods } from '@/util';
import ExportToExcel from '@/util/json_to_excel.service';

export default {
  name: 'EventsReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      events: [],
      itemsTable: [],
      filtro: {
        deviceId: [],
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59',
      },
      filtersForm: {
        email: {
          label: 'Usuario',
          type: String,
          model: 'users?all=true',
          item_value: 'email',
          autocomplete: false,
          optional: true,
        },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom');
            fields.from.setVisibility(val === 'custom');
            fields.fromHour.setVisibility(val === 'custom');
            fields.to_separator.setVisibility(val === 'custom');
            fields.to.setVisibility(val === 'custom');
            fields.toHour.setVisibility(val === 'custom');
          },
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true,
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false,
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true,
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true,
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false,
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  mounted() {},
  methods: {
    async load() {
      window.VMA.loading(true);
      const data = {
        email: this.filtro.email,
        ...this.period,
      };
      try {
        this.itemsTable = await ApiService({
          url: '/bitacora',
          method: 'get',
          params: data,
        });
      } catch (e) {
        console.error(e);
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' });
      } finally {
        window.VMA.loading(false);
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro,
      });
      const dialog = new Dialog({
        title: 'Filtrar alertamientos',
        actions: [
          {
            help: 'Filtrar',
            icon: 'mdi-filter',
            color: 'secondary',
            action: async () => {
              if (form.hasErrors()) {
                return;
              }
              this.selected = null;
              const item = form.getItem();
              this.filtro = item;
              if (item.periodo !== 'custom') {
                this.period = form.fields.periodo.getOption().getPeriod();
              } else {
                this.period = form.fields.periodo.getOption().getPeriod({
                  ..._.pick(item, 'from', 'to', 'fromHour', 'toHour'),
                });
              }
              this.load();
              dialog.close();
            },
          },
        ],
      });
      dialog.open();
      dialog.append(form);
    },
    toExcel() {
      ExportToExcel(
        'Sesiones '
          + moment(this.period.from).format('DD-MM-YYYY HH mm')
          + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          'Fecha y Hora': moment(item.serverTime).format('DD-MM-YYYY HH:mm'),
          Nombre: item.name,
          Usuario: item.username,
          IP: item.ipAddress,
          Origen: item.origin,
          Acción: item.action,
          Status: item.status,
        }))
      );
    },
  },
};
</script>

<style lang="scss">
</style>
